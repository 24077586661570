<template>  
  <div>
    <router-view  />
  </div>  
</template>

<script>


export default {
  name: 'App',
  watch: {
    $route() {      
        console.log('app watch route')
      /*   window.dataLayer.push({
          'event': 'eventNavigation',
          'category': '',
          'action': '',
          'label': ''
        });  
      } */
    }
  },
  created()  {
    //get the promo status - before, live, after   
        this.$store.dispatch("getSettings");
        this.$store.dispatch("getShops");
        this.$store.dispatch("getFileUrl");
  }
}
</script>