import Vue from 'vue'
import VueRouter from 'vue-router'

// import store from '@/setup/store.js'


Vue.use(VueRouter)

const routes = [    
   
    {
      path: '',
      name: 'Home',
      components:{            
        default: () => import(/* webpackChunkName: "Home" */ '../views/HomePage.vue')
      }
    },  
    {
      path: '/sikeres-regisztracio/:result',
      name: 'successReg',      
      components:{            
        default: () => import(/* webpackChunkName: "Home" */ '../views/RegResultPage.vue')
      }
    },     
    {
      path: '/email-megerosites/:secret',
      name: 'EmailVerification',
      // meta: {
      //   openPopup: 'validationModal'
      // },
      components:{
        default: () => import('@/views/EmailVerification.vue')
      }          
    }, 
    {
      path: '/elfelejtett-jelszo',
      name: 'ForgottenPsw',      
      components:{
        default: () => import('@/views/ForgottenPsw.vue')
      }          
    },
    {
      path: '/elfelejtett-jelszo/:secret',
      name: 'NewPassword_hu',
      // meta: {
      //   openPopup: 'newPasswordModal'
      // },
      components:{
        default: () => import('@/views/NewPasswordPage.vue')
      }          
    },    
    {
      path: '/nyeremeny',
      name: 'Winner',
      components:{
        default: () => import('@/views/Winner.vue')
      }  
    }, 
    {
      path: '/szallitas',
      name: 'Delivery',
      components:{
        default: () => import('@/views/Delivery.vue')
      }  
    }, 
    {
      path: "*",                   
      redirect: {            
        name: 'Home'
      },
    }                       
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  // scrollBehavior (to, from, savedPosition) {
  //   return savedPosition || { x: 0, y: 0, behavior:'smooth' }
  // }
  scrollBehavior: (to) => {    
    if (to.hash) {      
      Vue.nextTick(() => {
        setTimeout(() => {
          document.getElementById(to.hash.substring(1)).scrollIntoView();  
        }, 800);
        
      })
    }
    return {x: 0, y: 0}
  }
})

// function setLangFromSettings(){
//   const locale = localStorage.getItem("language")         
//   let setLangCode = null  

//     if (locale){            
//         let localeId = store.state.languages.find( item => item.code === locale).id 
//         store.state.language_id = localeId
//         setLangCode = store.state.languages.find( item => parseInt(item.id) === parseInt(localeId)).code
//     } else if ( navigator.language){
//         //set lang from the navigator settings    
//         let langCode = navigator.language.trim().split(/-|_/)[0]     
//         let localeId =  store.state.languages.find( item => item.code === langCode).id        
//         store.state.language_id = localeId
//         setLangCode = store.state.languages.find( item => item.code === langCode).code
//     }

//     return setLangCode
// }

// function setHtmlLang(lang){
//   document.documentElement.setAttribute("lang", lang);
// }

// router.beforeEach((to, from, next) => {         
//     const langTo = to.params.lang; // code of the lang
//     const supported_langs = store.state.languages       
    
//     console.log("beforeEach", to)

//     //not existing lang
//     if ( !supported_langs.find(item => item.code == langTo )){             
//       console.log('beforeeach NOT existing lang')
//       next({
//         name: 'Home',
//         params:{
//           lang: setLangFromSettings() //set the lang from the localstorage or browser settings
//         }
//       })   
//       setHtmlLang(setLangFromSettings())
//     } else {      
//       console.log('beforeeach existing lang')
//       //existing lang
//       const langToId = store.state.languages.find(item => item.code == langTo ).id      
//       if ( parseInt(store.state.language_id) !== parseInt(langToId)) {       
//         store.state.language_id = langToId;      
//       }

//       next({
//         params:{
//           lang: to.params.lang
//         }
//       })  

//       setHtmlLang(to.params.lang)
//     }
      
// })

export default router
